import { Carousel, Popover } from 'antd';
import React, { useState } from 'react';
import {
  Routes,
  Route,
} from "react-router-dom";
import './App.css'
import Remove from './remove'
const Index = ()=>{
  const [index, setIndex] = useState(0)
  const onChange = (currentSlide, next) => {
    setIndex(next);
  };
  return (
    <div className="App">

      <div className='bg' style={{
        backgroundImage: `url(./banner/BG_0${index+1}.jpg)`
      }}>
        <div className='context'>
          <div className='logo'></div>
          <div className='slogan'>
            <div className='meboo'></div>
            <div>More friends,</div>
            <div>More fun</div>
          </div>
          <div className='download'>
            <img src='./googleplay.png' onClick={() => { location.href="https://play.google.com/store/apps/details?id=com.kly.meboo"}} />
            <Popover content={'Coming Soon'}>
              <img src='./appstore.png' onClick={() => { }} />
            </Popover>
          </div>
          <div className='w'>
            <div className='option'>
              <a href='./terms.html' target="_blank" className='option_item'><img src='./Terms.png' />Terms</a>
              <Popover content={'contact@meboo-app.com'}>
                <div className='option_item'><img src='./Help.png' />Help</div>
              </Popover>
              <a href='./PrivacyPolicy.html' target="_blank"  className='option_item'><img src='./PrivacyPolicy.png' />PrivacyPolicy</a>
            </div>
            <div className='option'>
              @2023 https://web.meboo-app.com
            </div>
            <div className='option'>
              Company: MICHA LIMITED
            </div>
            <div className='option'>
              Address: UNIT 1603, 16TH FLOOR, THE L.PLAZA, 367-375 QUEEN'S ROAD CENTRAL , SHEUNG WAN
              , HONG KONG
            </div>
          </div>
        </div>
      </div>
      <Carousel beforeChange={onChange} className='carousel' autoplay autoplaySpeed={5000} easing='ease'>
        {[...new Array(5).keys()].map((_, index) => <div key={index+'key'}>
          <div className='banner' style={{
            backgroundImage: `url(./banner/BG_0${index + 1}.jpg)`
          }}></div>
        </div>)}

      </Carousel>
    </div>
  )
}
const App = () => {

  return <Routes>
  <Route path="/" element={<Index />} />
    <Route path="/deleteAccount.html" element={<Remove />} />
</Routes>

}

export default App;