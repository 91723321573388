import { Carousel, Popover, Button, message } from 'antd';
import React, { useState } from 'react';
import './App.css'

const App = () => {
  const [userInfo, setUserInfo] = useState({})
  function handleCredentialResponse (response) {
    // decodeJwtResponse() is a custom function defined by you
    // to decode the credential response.
    const responsePayload = decodeJwtResponse(response.credential);
    var email = responsePayload.email;
    fetch('https://sc2-api.imeboo.com/api/users/getUserBaseInfoByEmail', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ "email": email })
    })
      .then(response => response.json())
      .then(response => {
          console.log(111,response);
        if (typeof response.data == 'undefined') {
          message.error("The account has been deleted or does not exist.")
          return;
        }
        console.log(response);
        setUserInfo(response)
        let nickName = response.nickName;
      })
  }

  function decodeJwtResponse (token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  function del(){
    setUserInfo({})
    message.success('Your account has beensuccessfully deleted')
  }
  return (
    <div>

       <div className="google_login">
        <h4 style={{
          marginBlock: '10px',
          fontSize: '28px',

        }}>Cancellation Request</h4>
        <h6
        style={{
          marginBlockStart: '10px',
          fontSize: '18px',
          color: '#666'
        }}
        >Please verify identity first</h6>
        <div style={{
          width: '182px',
          margin: 'auto'
        }}>
          {!!userInfo.nickName ?
          <>
          <div style={{
        display: 'flex',
        alignItems:"center",
        gap: '12px',
        // marginBottom: '24px'
      }}>
        <img style={{width: '40px', height: '40px', borderRadius: '100%'}} src={userInfo.avatarUrl}/>
        <span style={{
          fontSize: '20px',
        }}>{userInfo.nickName}</span>
      </div>
      <Button onClick={del}>Confirm Account Deletion</Button>
      </>
      :
      <>
        <div
            id="g_id_onload"
            data-client_id="241368681569-97deun192jscl83d8n5345vkfasf6rcr.apps.googleusercontent.com"
            data-context="signin"
            data-ux_mode="popup"
            data-callback="handleCredentialResponse"
            data-nonce=""
            data-auto_prompt="false"
          >
          </div>


          <div
            className="g_id_signin"
            data-type="standard"
            data-shape="rectangular"
            data-theme="outline"
            data-text="signin_with"
            data-size="large"
            data-locale="en-US"
            data-logo_alignment="left"
          ></div>
        </>}
        </div>
      </div>
    </div>
  )
}

export default App;